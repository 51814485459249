import PropTypes from 'prop-types';
import React, { useEffect } from "react";

import {
  SectionHowToGetFinancing,
  SectionConsultation,
  SectionLeasingAdvantages,
  SectionLeasingScheme
} from "@product-site-frontend/shared";
import { useLocation } from "@reach/router";
import { graphql } from 'gatsby';

import config from '../../config/website';
import logo from '../../static/images/logo.png';
import metaImage from '../../static/images/metaImage.jpg';
import SectionAboutLeasing from '../components/SectionAboutLeasing';
import SectionBlog from '../components/SectionBlog';
import SectionBusinessSegments from '../components/SectionBusinessSegments';
import SectionLeasingTabs from '../components/SectionLeasingTabs';
import SectionMain from '../components/SectionMain';
import SectionPartners from '../components/SectionPartners';
import SectionPromotions from "../components/SectionPromotions";
import SEO from '../components/SEO';

IndexPage.propTypes = {
  data: PropTypes.shape({
    aboutLeasingContent: PropTypes.object,
    businessSegmentsContent: PropTypes.object,
    leasingAdvantagesContent: PropTypes.object,
    leasingSchemeContent: PropTypes.object,
    mainSectionContent: PropTypes.object,
    partners: PropTypes.object,
    promotions: PropTypes.object,
    home: PropTypes.object,
    articles: PropTypes.object,
    financingContent: PropTypes.object,
  }),
};

export default function IndexPage({ data }) {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      let top = document.getElementById(hash.slice(1).split('?')[0])?.offsetTop - 80;
      window.scrollTo(0, top)
    }
  }, [hash]);

  const {
    aboutLeasingContent,
    articles,
    businessSegmentsContent,
    financingContent,
    home,
    leasingAdvantagesContent,
    leasingSchemeContent,
    partners,
    promotions,
  } = data;

  return (
    <>
      <SEO
        microData={{
          '@context': 'http://schema.org',
          '@type': 'Organization',
          image: metaImage,
          url: config.siteUrl,
          logo: logo,
          name: 'ООО «АРЕНЗА-ПРО»',
          description: 'Аренза – лизинговая компания для малого бизнеса, юридических лиц и ИП ✅ Взять оборудование в лизинг онлайн из любой точки России ✅ Выгодные условия, минимальные проценты',
          email: 'info@arenza.ru',
          telephone: '+7-800-775-7685',
          address: {
            '@type': 'PostalAdress',
            streetAddress: 'Пресненская набережная, д. 6с2',
            addressLocality: 'Москва',
            addressCountry: 'RU',
            postalCode: '123112'
          }
        }}
      />

      <SectionMain content={home.main} />
      <SectionAboutLeasing content={aboutLeasingContent} />
      <SectionPromotions content={promotions.nodes} />
      <SectionBusinessSegments content={businessSegmentsContent} />
      <SectionLeasingScheme content={leasingSchemeContent} />
      <SectionLeasingTabs rate={parseFloat(home.main.calculatorRate)} />
      <SectionLeasingAdvantages content={leasingAdvantagesContent} />
      <SectionHowToGetFinancing content={financingContent} />
      <SectionBlog articles={articles.nodes} />
      <SectionPartners content={home.partners} partners={partners.nodes} />
      <SectionConsultation />
    </>
  );
}

export const query = graphql`
  query IndexPageQuery {
    home: strapiHome {
      main {
        title
        description
        term
        dealAmount
        advanceRate
        bgDesktop {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
        calculatorRate
      }
      partners {
        title
        cards {
          id
          alternativeText
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      sellMore {
        title
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    aboutLeasingContent: strapiAboutLeasing {
      title
      description
      cards {
        text
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
    }
    businessSegmentsContent: strapiBusinessSegments {
      title
      description
      cards {
        text
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 512)
            }
          }
        }
      }
    }
    leasingSchemeContent: strapiLeasingScheme {
      title
      caption
      steps {
        title
        description
      }
    }
    leasingAdvantagesContent: strapiLeasingAdvantages {
      cards {
        title
        description
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
      title
    }
    financingContent: strapiFinancing {
      title
      steps {
        title
        description
      }
      description
      bgDesktop {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, placeholder: NONE)
          }
        }
      }
    }
    partners: allStrapiPartners(sort: { fields: order }) {
      nodes {
        name
        order
        url
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
    }
    articles: allStrapiArticles(sort: { order: DESC, fields: strapiId }) {
      nodes {
        strapiId
        slug
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    promotions: allStrapiPromotions(sort: { order: DESC, fields: strapiId }) {
      nodes {
        strapiId
        slug
        seo {
          title
        }
        preview {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
        }
      }
    }
  }
`;
